/*
File Name: 		custom.css
Description:  You can add your custom CSS here and it will overwrite template styles
*/

.color-white {
	color: white !important;
}
.text-shadow-3 {
	text-shadow: 0 0 12px rgba(0, 0, 0, 0.7) !important;
}
.header-logo__img {
	width: 150px;
}
.modal-account__item-register-txt {
	font-weight: bold !important;
	text-shadow: 0 0 8px rgba(0, 0, 0, 0.8) !important;
}
.modal-account__item-register-txt a {
	text-shadow: 0 0 8px rgba(0, 0, 0, 0.8) !important;
}
.header__primary {
	border-bottom: 1px solid #f4f4f4;
}

.player-heading::before {
	background-image: none;
	background-repeat: no-repeat;
}